import * as React from 'react'

import { graphql } from 'gatsby'

import PostTemplate from '../../templates/post-template'
import ProjectTemplate from '../../templates/project-template'

const MdxPage = ({ data }) => {
  const pageData = data.mdx
  const category = pageData.frontmatter?.category

  const templates = {
    blog: <PostTemplate data={pageData} />,
    projects: <ProjectTemplate data={pageData} />,
  }

  return <>{templates[category] ? templates[category] : null}</>
}

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      body
      tableOfContents
      frontmatter {
        title
        category
        tags
        hero {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default MdxPage
