import * as React from 'react'
import { useThemeContext } from '../../context/theme-context'

interface IPropTypes {
  items: Array<any>
}

const InlineTableOfContents = ({ items }: IPropTypes) => {
  const { theme } = useThemeContext()
  return (
    <div className="flex flex-col">
      <h4 className="font-bold text-3xl mb-6">Table of Contents</h4>
      <ol className="list-decimal list-inside gap-y-2">
        {items.map((item: any) => {
          return (
            <li key={item.url}>
              <a
                href={item.url}
                className={`${
                  theme === 'theme-light' ? 'text-[#3b82f6]' : 'text-[#60a5fa]'
                } font-semibold underline`}
              >
                {item.title}
              </a>
            </li>
          )
        })}
      </ol>
    </div>
  )
}

export default InlineTableOfContents
