import * as React from 'react'

import { Link } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Img from 'gatsby-image'

import Layout from '../components/core/Layout'
import AuthorBioHeadshot from '../images/AuthorBioHeadshot.png'

const ProjectTemplate = ({ data }) => {
  const post = data

  return (
    <Layout>
      <div className="grid grid-cols-12 max-w-3xl mx-auto">
        <div className="col-span-12">
          <article className="prose flex-1 w-full bg-primary py-8 mb-20 px-6 text-lg antialiased">
            <div className="flex flex-col gap-y-4 mb-16">
              <h1 className="text-4xl font-bold text-primary">
                {post.frontmatter.title}
              </h1>

              <div className="flex justify-between align-center items-center">
                <Link to="/about">
                  <div className="flex gap-x-2 align-center items-center">
                    <img
                      className="w-9 h-9 rounded-full border border-white"
                      src={AuthorBioHeadshot}
                      alt="Yannis Panagis Profile"
                    />
                    <p className="opacity-60 font-medium hover:text-accent">
                      Yannis Panagis
                    </p>
                  </div>
                </Link>
                <p className="font-medium opacity-60">12th Aug 2021</p>
              </div>
              {post.frontmatter.hero && (
                <Img
                  className="rounded-md"
                  fluid={post.frontmatter.hero.childImageSharp.fluid}
                />
              )}
            </div>
            <MDXRenderer>{post.body}</MDXRenderer>
          </article>
        </div>
        {/* TODO: fix issues with table of contents component */}
        {/* <div className="hidden xl:block xl:col-span-3 mt-32 pt-1">
          {post?.tableOfContents?.items && (
            <TableOfContents items={post.tableOfContents.items} />
          )}
        </div> */}
      </div>
    </Layout>
  )
}

export default ProjectTemplate
